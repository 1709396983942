import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { Helmet } from 'react-helmet';
import { Sun, Battery, Zap, Anchor, Cpu } from 'lucide-react';
import SolarPanelPage from './products/SolarPanelPage';
import BatteryPage from './products/BatteryPage';
import InverterPage from './products/InverterPage';
import MountingSystemPage from './products/MountingSystemPage';
import ChargeControllerPage from './products/ChargeControllerPage';

const ProductPage = () => {
  const { isDarkMode } = useTheme();
  const { category } = useParams();

  const products = {
    all: {
      title: 'Solar Energy Products',
      icon: Sun,
      description: 'Explore our range of solar energy products for your home or business.',
      categories: [
        { id: 'panels', name: 'Solar Panels', icon: Sun, description: 'Convert sunlight into electricity' },
        { id: 'batteries', name: 'Batteries', icon: Battery, description: 'Store energy for use during non-sunny periods' },
        { id: 'inverters', name: 'Inverters', icon: Zap, description: 'Convert DC to AC for home use' },
        { id: 'mounting', name: 'Mounting Systems', icon: Anchor, description: 'Secure solar panels to roofs or ground' },
        { id: 'controllers', name: 'Charge Controllers', icon: Cpu, description: 'Regulate voltage and current from solar panels to batteries' },
      ],
    },
  };

  const renderProductPage = () => {
    switch(category) {
      case 'panels':
        return <SolarPanelPage />;
      case 'batteries':
        return <BatteryPage />;
      case 'inverters':
        return <InverterPage />;
      case 'mounting':
        return <MountingSystemPage />;
      case 'controllers':
        return <ChargeControllerPage />;
      default:
        return renderAllProducts();
    }
  };

  const renderAllProducts = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {products.all.categories.map((cat) => (
        <Link 
          key={cat.id} 
          to={`/product/${cat.id}`}
          className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-white hover:bg-amber-100'} shadow-md transition-all duration-300 ease-in-out`}
        >
          <div className="flex items-center justify-between mb-4">
            <cat.icon className={`w-10 h-10 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          </div>
          <h3 className="text-xl font-semibold mb-2">{cat.name}</h3>
          <p>{cat.description}</p>
        </Link>
      ))}
    </div>
  );

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>{category === 'all' ? 'Solar Energy Products' : `${products.all.categories.find(cat => cat.id === category)?.name}`} | Solar Insight</title>
        <meta name="description" content={`Learn about ${category === 'all' ? 'solar energy products' : products.all.categories.find(cat => cat.id === category)?.name.toLowerCase()} for solar energy systems in South Africa.`} />
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          {category === 'all' ? 'Solar Energy Products' : products.all.categories.find(cat => cat.id === category)?.name}
        </h1>

        {renderProductPage()}
      </main>
    </div>
  );
};

export default ProductPage;