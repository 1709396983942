import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout';
import Home from './components/Home';
import Dashboard from './components/dashboard/Dashboard';
import LoadSheddingPage from './components/loadShedding/LoadSheddingPage';
import ProductList from './components/products/ProductList';
import ProductDetail from './components/products/ProductDetail';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import PrivateRoute from './components/auth/PrivateRoute';
import SavingsCalculator from './components/calculator/SavingsCalculator';
import PanelSizeCalculator from './components/calculator/PanelSizeCalculator';
import BatteryCapacityCalculator from './components/calculator/BatteryCapacityCalculator';
import InsightPage from './components/InsightPage';
import SolarEnergyNews from './components/NewsPage';
import ReviewsPage from './components/ReviewsPage';
import ProductPage from './components/ProductPage';
import SolarPanelPage from './components/products/SolarPanelPage';
import BatteryPage from './components/products/BatteryPage';
import InverterPage from './components/products/InverterPage';
import MountingSystemPage from './components/products/MountingSystemPage';
import ChargeControllerPage from './components/products/ChargeControllerPage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/load-shedding" element={<LoadSheddingPage />} />
              <Route path="/product/all" element={<ProductPage />} />
              <Route path="/product/panels" element={<SolarPanelPage />} />
              <Route path="/product/batteries" element={<BatteryPage />} />
              <Route path="/product/inverters" element={<InverterPage />} />
              <Route path="/product/mounting" element={<MountingSystemPage />} />
              <Route path="/product/controllers" element={<ChargeControllerPage />} />
              <Route path="/products/:id" element={<ProductDetail />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/calculators/savings" element={<SavingsCalculator />} />
              <Route path="/calculators/panel" element={<PanelSizeCalculator />} />
              <Route path="/calculators/battery" element={<BatteryCapacityCalculator />} />
              <Route path="/insights" element={<InsightPage />} />
              <Route path="/news" element={<SolarEnergyNews />} />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;