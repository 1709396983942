import React, { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { Star, Search, X, Filter, Award } from 'lucide-react';

const ReviewsPage = () => {
  const { isDarkMode } = useTheme();
  const { user, isAuthenticated } = useAuth();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isWriteReviewOpen, setIsWriteReviewOpen] = useState(false);
  const [newReview, setNewReview] = useState({ rating: 0, comment: '' });
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [topProduct, setTopProduct] = useState(null);

  const categories = [
    { id: 'all', name: 'All Products' },
    { id: 'panels', name: 'Solar Panels' },
    { id: 'inverters', name: 'Inverters' },
    { id: 'batteries', name: 'Batteries' },
  ];

  useEffect(() => {
    fetchProducts();
    fetchReviews();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      fetchTopProduct();
      if (!selectedProduct) {
        setSelectedProduct(products[0]);
      }
    }
  }, [products]);

  const fetchProducts = () => {
    // Simulated API call
    const sampleProducts = [
      { id: 1, name: 'SunPower X-Series Solar Panel', category: 'panels', avgRating: 4.5, reviewCount: 120 },
      { id: 2, name: 'Tesla Powerwall 2', category: 'batteries', avgRating: 4.7, reviewCount: 89 },
      { id: 3, name: 'Enphase IQ7+ Microinverter', category: 'inverters', avgRating: 4.6, reviewCount: 75 },
      { id: 4, name: 'LG NeON 2 Solar Panel', category: 'panels', avgRating: 4.4, reviewCount: 62 },
      { id: 5, name: 'SolarEdge HD-Wave Inverter', category: 'inverters', avgRating: 4.3, reviewCount: 55 },
      { id: 6, name: 'LG Chem RESU Battery', category: 'batteries', avgRating: 4.5, reviewCount: 43 },
    ];
    setProducts(sampleProducts);
  };

  const fetchReviews = () => {
    // Simulated API call
    const sampleReviews = [
      { id: 1, productId: 1, user: 'SolarFan123', rating: 5, comment: 'Excellent efficiency!', date: '2024-09-15' },
      { id: 2, productId: 1, user: 'EcoWarrior', rating: 4, comment: 'Great performance, a bit pricey', date: '2024-09-10' },
      { id: 3, productId: 1, user: 'TechGuru', rating: 5, comment: 'Top-notch quality, highly recommended', date: '2024-09-05' },
      { id: 4, productId: 1, user: 'OffGridLiving', rating: 4, comment: 'Good output even in low light conditions', date: '2024-08-28' },
    ];
    setReviews(sampleReviews);
  };

  const fetchTopProduct = () => {
    // In a real app, this would be an API call to get the top product
    // For now, we'll just select the product with the highest average rating
    const top = products.reduce((prev, current) => 
      (prev.avgRating > current.avgRating) ? prev : current
    );
    setTopProduct(top);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleWriteReview = () => {
    if (!isAuthenticated) {
      alert('Please log in to write a review.');
      return;
    }
    if (!selectedProduct) {
      alert('Please select a product to review.');
      return;
    }
    setIsWriteReviewOpen(true);
  };

  const handleSubmitReview = () => {
    if (!isAuthenticated || !selectedProduct) {
      alert('Please log in and select a product to submit a review.');
      return;
    }

    if (newReview.rating === 0 || newReview.comment.trim() === '') {
      alert('Please provide both a rating and a comment.');
      return;
    }

    const newReviewObject = {
      id: reviews.length + 1,
      productId: selectedProduct.id,
      user: user.username,
      rating: newReview.rating,
      comment: newReview.comment,
      date: new Date().toISOString().split('T')[0],
    };

    setReviews([newReviewObject, ...reviews]);
    
    setProducts(products.map(p => {
      if (p.id === selectedProduct.id) {
        const newTotalRating = p.avgRating * p.reviewCount + newReview.rating;
        const newReviewCount = p.reviewCount + 1;
        return {
          ...p,
          avgRating: newTotalRating / newReviewCount,
          reviewCount: newReviewCount,
        };
      }
      return p;
    }));

    setNewReview({ rating: 0, comment: '' });
    setIsWriteReviewOpen(false);
  };

  const filteredProducts = products.filter(product =>
    (selectedCategory === 'all' || product.category === selectedCategory) &&
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const productReviews = selectedProduct
    ? reviews.filter(review => review.productId === selectedProduct.id)
    : [];

  const StarRating = ({ rating }) => {
    return (
      <div className="flex items-center">
        <div className="flex mr-2">
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} className="relative inline-block w-5 h-5">
              <Star 
                className={`absolute w-5 h-5 text-gray-300 ${isDarkMode ? 'filter drop-shadow-glow' : ''}`} 
                fill="currentColor"
              />
              <Star 
                className={`absolute w-5 h-5 text-yellow-400 ${isDarkMode ? 'filter drop-shadow-glow' : ''}`} 
                fill="currentColor"
                style={{
                  clipPath: `inset(0 ${Math.max(0, (star - rating)) * 100}% 0 0)`
                }}
              />
            </span>
          ))}
        </div>
        <span className="text-sm font-semibold">{rating.toFixed(1)}</span>
      </div>
    );
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Solar Product Reviews | Solar Insight</title>
        <meta name="description" content="Read and submit reviews for solar energy products including panels, inverters, and batteries." />
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          Solar Product Reviews
        </h1>

        {/* Top Product of the Month Section */}
        {topProduct && (
          <div className={`mb-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
            <div className="flex items-center mb-4">
              <Award className={`w-8 h-8 mr-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
              <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Top Product of the Month
              </h2>
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold mb-2">{topProduct.name}</h3>
                <p className="text-sm mb-2">{categories.find(c => c.id === topProduct.category).name}</p>
                <div className="flex items-center">
                  <StarRating rating={topProduct.avgRating} />
                  <span className="ml-2">({topProduct.reviewCount} reviews)</span>
                </div>
              </div>
              <button 
                onClick={() => handleProductSelect(topProduct)}
                className={`mt-4 md:mt-0 px-6 py-2 rounded-lg ${
                  isDarkMode 
                    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                    : 'bg-amber-500 hover:bg-amber-600 text-white'
                } transition-colors duration-200`}
              >
                View Details
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-1">
            <div className="mb-4 relative">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-full pl-10 pr-4 py-2 rounded-lg ${isDarkMode ? 'bg-blue-800 text-blue-100' : 'bg-white text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Filter by Category</label>
              <div className="flex flex-wrap">
                {categories.map(category => (
                  <button
                    key={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                    className={`mr-2 mb-2 px-3 py-1 rounded-full text-sm ${
                      selectedCategory === category.id
                        ? isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'
                        : isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-200 text-amber-800'
                    } transition-colors duration-200`}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
            </div>
            <ul className="space-y-2">
              {filteredProducts.map(product => (
                <li key={product.id}>
                  <button
                    onClick={() => handleProductSelect(product)}
                    className={`w-full text-left px-4 py-2 rounded-lg transition-colors duration-200 ${
                      selectedProduct?.id === product.id
                        ? isDarkMode ? 'bg-blue-600' : 'bg-amber-200'
                        : isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-100'
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <span className="font-medium">{product.name}</span>
                      <span className="flex items-center text-sm">
                        <StarRating rating={product.avgRating} />
                        <span className="ml-2">({product.reviewCount})</span>
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="md:col-span-2">
            {selectedProduct && (
              <>
                <div className={`mb-6 p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                  <h2 className="text-2xl font-bold mb-2">{selectedProduct.name}</h2>
                  <p className="text-sm mb-2">{categories.find(c => c.id === selectedProduct.category).name}</p>
                  <div className="flex items-center mb-4">
                    <span className="text-lg font-semibold mr-2">Average Rating:</span>
                    <StarRating rating={selectedProduct.avgRating} />
                    <span className="ml-2">({selectedProduct.reviewCount} reviews)</span>
                  </div>
                  <button 
                    onClick={handleWriteReview}
                    className={`px-6 py-2 rounded-lg ${isDarkMode ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' : 'bg-amber-500 hover:bg-amber-600 text-white'} transition-colors duration-200`}
                  >
                    Write a Review
                  </button>
                </div>

                {productReviews.map(review => (
                  <div key={review.id} className={`mb-4 p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                    <div className="flex items-center justify-between mb-2">
                      <span className="font-semibold">{review.user}</span>
                      <StarRating rating={review.rating} />
                    </div>
                    <p className="mb-2">{review.comment}</p>
                    <div className={`text-sm ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>
                      {new Date(review.date).toLocaleDateString()}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </main>

      {isWriteReviewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} w-full max-w-md`}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Write a Review</h2>
              <button onClick={() => setIsWriteReviewOpen(false)} className={`${isDarkMode ? 'text-blue-200 hover:text-blue-100' : 'text-amber-700 hover:text-amber-800'}`}>
                <X />
              </button>
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Rating</label>
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => setNewReview({ ...newReview, rating: star })}
                    className={`mr-1 ${star <= newReview.rating ? 'text-yellow-400' : isDarkMode ? 'text-blue-300' : 'text-amber-300'}`}
                  >
                    <Star fill={star <= newReview.rating ? 'currentColor' : 'none'} className="w-8 h-8" />
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Comment</label>
              <textarea
                value={newReview.comment}
                onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                className={`w-full p-2 rounded-lg ${isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-100 text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                rows="4"
              ></textarea>
            </div>
            <button
              onClick={handleSubmitReview}
              className={`w-full py-2 rounded-lg ${isDarkMode ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' : 'bg-amber-500 hover:bg-amber-600 text-white'} transition-colors duration-200`}
            >
              Submit Review
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsPage;