import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Anchor } from 'lucide-react';

const MountingSystemPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'Roof Mount', description: 'Most common for residential installations' },
    { name: 'Ground Mount', description: 'Ideal for large-scale installations or when roof space is limited' },
    { name: 'Ballasted Mount', description: 'Non-penetrating option for flat roofs' },
  ];

  const features = [
    'Material durability',
    'Ease of installation',
    'Wind and load ratings',
    'Compatibility with different roof types',
    'Warranty',
  ];

  const brands = ['Schletter', 'K2 Systems', 'Renusol', 'IronRidge'];

  return (
    <div>
      <p className="mb-8 text-lg">
        Mounting systems securely attach solar panels to roofs or the ground, crucial for system longevity and performance.
      </p>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Types of Mounting Systems</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {types.map((type, index) => (
            <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
              <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
              <p>{type.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Key Features</h2>
        <ul className="list-disc list-inside">
          {features.map((feature, index) => (
            <li key={index} className="mb-2">{feature}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Top Brands in SA</h2>
        <div className="flex flex-wrap">
          {brands.map((brand, index) => (
            <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
              {brand}
            </span>
          ))}
        </div>
      </section>

      <section>
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Price Range</h2>
        <p className="text-lg font-semibold">R2,000 - R10,000 (depending on system size and type)</p>
      </section>
    </div>
  );
};

export default MountingSystemPage;