import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Sun, Calculator, Info, HelpCircle, ArrowRight, Zap, DollarSign, TrendingUp, Leaf, MapPin } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import AutocompleteInput from './AutocompleteInput';

const PanelSizeCalculator = () => {
  const { isDarkMode } = useTheme();

  const [inputs, setInputs] = useState({
    location: '',
    dailyEnergyUsage: 20,
    roofArea: 30,
    roofPitch: 30,
    roofOrientation: 'North',
    panelType: 'monocrystalline',
  });

  const [results, setResults] = useState({});
  const [sunHours, setSunHours] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [error, setError] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const [locationInput, setLocationInput] = useState(inputs.location);

  const handleLocationChange = (value) => {
    setLocationInput(value);
  };

  const handleLocationSelect = async (address, latLng) => {
    setLocationInput(address);
    setInputs((prev) => ({ ...prev, location: address }));
    setCoordinates({ lat: latLng.lat, lon: latLng.lng });
  };

  // Fetch sun hours when coordinates are available
  useEffect(() => {
    if (coordinates) {
      fetchSunHours(coordinates.lat, coordinates.lon);
    }
  }, [coordinates]);

  // Recalculate results when inputs or sunHours change
  useEffect(() => {
    if (sunHours !== null) {
      calculateResults();
    }
  }, [inputs, sunHours]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Input validation
    if (name === 'dailyEnergyUsage' || name === 'roofArea' || name === 'roofPitch') {
      if (value < 0) return; // Do not allow negative values
    }

    if (name === 'roofPitch') {
      if (value > 90) return; // Roof pitch cannot exceed 90 degrees
    }

    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const fetchSunHours = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://re.jrc.ec.europa.eu/api/v5_2/seriescalc?lat=${lat}&lon=${lon}&startyear=2016&endyear=2016&outputformat=json&browser=0`
      );
      const data = await response.json();

      if (data && data.outputs && data.outputs.monthly) {
        const monthlyData = data.outputs.monthly;
        let totalSunHours = 0;
        monthlyData.forEach((month) => {
          totalSunHours += month.H_m;
        });
        const avgSunHours = totalSunHours / (365 * 12); // Average daily sun hours

        setSunHours(avgSunHours);
        setError(null);
      } else {
        throw new Error('Invalid data structure from PVGIS API');
      }
    } catch (error) {
      console.error('Error fetching sun hours:', error);
      setError('Error fetching solar data. Using estimated values for calculations.');
      setSunHours(5.5); // Default value for South Africa
    }
    calculateResults(); // Always calculate results, even if using fallback data
  };

  const calculateResults = () => {
    const {
      dailyEnergyUsage,
      roofArea,
      roofPitch,
      roofOrientation,
      panelType,
    } = inputs;

    // Ensure numeric values
    const dailyUsage = parseFloat(dailyEnergyUsage);
    const area = parseFloat(roofArea);
    const pitch = parseFloat(roofPitch);

    if (
      isNaN(dailyUsage) ||
      isNaN(area) ||
      isNaN(pitch) ||
      dailyUsage <= 0 ||
      area <= 0 ||
      pitch < 0 ||
      pitch > 90
    ) {
      setError('Please enter valid numeric values for all inputs.');
      return;
    }

    // Calculate the required system size in Watts
    const systemSize = (dailyUsage * 1000) / sunHours; // in Watts

    // Get panel output and area based on panel type
    const panelOutput = getPanelOutput(panelType); // in Watts
    const panelArea = getPanelArea(panelType); // in m²

    // Calculate the maximum number of panels that can fit on the roof
    const maxPanelsByArea = Math.floor(area / panelArea);

    // Calculate the required number of panels
    const requiredPanels = Math.ceil(systemSize / panelOutput);

    // Determine the actual number of panels to install
    const numberOfPanels = Math.min(requiredPanels, maxPanelsByArea);

    // Recalculate the actual system size based on the number of panels
    const actualSystemSize = (numberOfPanels * panelOutput) / 1000; // in kW

    // Estimated cost at R15 per Watt
    const estimatedCost = actualSystemSize * 1000 * 15; // in Rands

    // Calculate efficiency factor based on roof pitch and orientation
    const efficiencyFactor = getEfficiencyFactor(pitch, roofOrientation);

    // Calculate daily energy generation
    const dailyGeneration = (
      actualSystemSize *
      sunHours *
      efficiencyFactor
    ).toFixed(2); // in kWh

    // Calculate annual savings at R2.5 per kWh
    const annualSavings = (dailyGeneration * 365 * 2.5).toFixed(2); // in Rands

    // Calculate annual carbon reduction (0.9 kg CO2 per kWh)
    const carbonReduction = (dailyGeneration * 365 * 0.9).toFixed(2); // in kg CO₂

    // Calculate payback period
    const paybackPeriod = (estimatedCost / annualSavings).toFixed(1); // in years

    // Calculate ROI after 25 years
    const roiAfter25Years = (
      ((annualSavings * 25 - estimatedCost) / estimatedCost) *
      100
    ).toFixed(1); // in %

    console.log('Calculated results:', {
      recommendedSystemSize: actualSystemSize.toFixed(2),
      numberOfPanels,
      estimatedCost: estimatedCost.toFixed(2),
      dailyGeneration,
      annualSavings,
      carbonReduction,
      paybackPeriod,
      roiAfter25Years,
    });

    setResults({
      recommendedSystemSize: actualSystemSize.toFixed(2),
      numberOfPanels,
      estimatedCost: estimatedCost.toFixed(2),
      dailyGeneration,
      annualSavings,
      carbonReduction,
      paybackPeriod,
      roiAfter25Years,
    });
    setError(null);
  };

  // Function to get panel output based on panel type
  const getPanelOutput = (type) => {
    switch (type) {
      case 'monocrystalline':
        return 330;
      case 'polycrystalline':
        return 300;
      case 'thinFilm':
        return 280;
      default:
        return 300;
    }
  };

  // Function to get panel area based on panel type
  const getPanelArea = (type) => {
    // Approximate areas in m²
    switch (type) {
      case 'monocrystalline':
        return 1.6;
      case 'polycrystalline':
        return 1.7;
      case 'thinFilm':
        return 2.0;
      default:
        return 1.7;
    }
  };

  // Function to get efficiency factor based on roof pitch and orientation
  const getEfficiencyFactor = (pitch, orientation) => {
    let factor = 1;

    // Adjust for roof orientation
    switch (orientation) {
      case 'North':
        factor *= 1.0; // Optimal
        break;
      case 'East':
      case 'West':
        factor *= 0.9; // About 10% less efficient
        break;
      case 'South':
        factor *= 0.8; // About 20% less efficient
        break;
      default:
        factor *= 0.9;
        break;
    }

    // Adjust for roof pitch
    if (pitch < 10 || pitch > 40) {
      factor *= 0.95; // Slightly less efficient
    }

    return factor;
  };

  // Styling
  const bgColor = isDarkMode ? 'bg-blue-900' : 'bg-amber-50';
  const textColor = isDarkMode ? 'text-amber-100' : 'text-amber-900';
  const inputBgColor = isDarkMode ? 'bg-blue-800' : 'bg-white';
  const inputBorderColor = isDarkMode ? 'border-blue-700' : 'border-amber-300';
  const iconHoverColor = isDarkMode
    ? 'hover:text-amber-300'
    : 'hover:text-amber-600';
  const resultsBgColor = isDarkMode ? 'bg-blue-800' : 'bg-amber-100';
  const headerColor = isDarkMode ? 'text-yellow-400' : 'text-amber-800';

  const commonQuestions = [
    {
      q: 'How does roof orientation affect solar panel performance?',
      a: 'In the Southern Hemisphere, north-facing roofs are ideal for solar panels as they receive the most direct sunlight throughout the day. East or west-facing roofs can still be suitable but may produce about 10-20% less energy. South-facing roofs are generally less optimal.',
    },
    {
      q: "What's the difference between monocrystalline and polycrystalline panels?",
      a: 'Monocrystalline panels are generally more efficient and perform better in low-light conditions, but are more expensive. Polycrystalline panels are less expensive but slightly less efficient. The choice depends on your budget, available space, and energy needs.',
    },
    {
      q: 'How do I calculate my return on investment (ROI) for solar panels?',
      a: "ROI for solar panels is calculated by dividing the net profit (total savings over the system's lifespan minus the initial investment) by the initial investment, then multiplying by 100 to get a percentage. Our calculator provides an estimated 25-year ROI based on current electricity prices and system costs.",
    },
  ];

  return (
    <div className={`p-4 sm:p-6 rounded-lg shadow-lg ${bgColor} ${textColor}`}>
      <Helmet>
        <title>Expert Solar Panel Size Calculator | GetSolar South Africa</title>
        <meta
          name="description"
          content="Use our advanced solar panel size calculator to determine the optimal system for your South African home. Get expert recommendations on system capacity, panel numbers, and potential savings."
        />
        <meta
          name="keywords"
          content="solar panel size, South Africa solar, PV system calculator, solar ROI, green energy savings"
        />
        <meta
          property="og:title"
          content="Expert Solar Panel Size Calculator | GetSolar South Africa"
        />
        <meta
          property="og:description"
          content="Determine the perfect solar panel system size for your South African home with our advanced calculator. Get personalized recommendations and ROI estimates."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://getsolar.co.za/calculator/panel-size"
        />
        <meta
          property="og:image"
          content="https://getsolar.co.za/images/solar-calculator-og.jpg"
        />
        <link
          rel="canonical"
          href="https://getsolar.co.za/calculator/panel-size"
        />
      </Helmet>

      <h1
        className={`text-3xl font-bold mb-4 flex items-center ${headerColor}`}
      >
        <Sun className={`mr-2 ${iconHoverColor}`} /> Expert Solar Panel Size
        Calculator
      </h1>

      <p className="mb-6">
        Optimize your solar investment with our advanced calculator. Tailored
        for South African conditions, it provides precise recommendations based
        on your specific location and roof characteristics.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="relative group">
          <label className="block mb-2 font-semibold flex items-center">
            Location
            <HelpCircle size={16} className={`ml-2 cursor-help ${iconHoverColor}`} />
          </label>
          <AutocompleteInput
            value={locationInput}
            onChange={handleLocationChange}
            onSelect={handleLocationSelect}
            error={error}
          />
          <div
            id="location-tooltip"
            role="tooltip"
            className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"
          >
            Enter your city for accurate sun hour data.
          </div>
        </div>
        <InputField
          label="Daily Energy Usage (kWh)"
          name="dailyEnergyUsage"
          value={inputs.dailyEnergyUsage}
          onChange={handleInputChange}
          tooltip="Your average daily electricity consumption. Check your Eskom bill for this information."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
          type="number"
        />
        <InputField
          label="Available Roof Area (m²)"
          name="roofArea"
          value={inputs.roofArea}
          onChange={handleInputChange}
          tooltip="The area of your roof suitable for solar panel installation. Consider orientation and shading."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
          type="number"
        />
        <InputField
          label="Roof Pitch (degrees)"
          name="roofPitch"
          value={inputs.roofPitch}
          onChange={handleInputChange}
          tooltip="The angle of your roof. Most residential roofs in South Africa are between 15-30 degrees."
          bgColor={inputBgColor}
          borderColor={inputBorderColor}
          textColor={textColor}
          iconHoverColor={iconHoverColor}
          type="number"
        />
        <div className="relative group">
          <label className="block mb-2 font-semibold flex items-center">
            Roof Orientation
            <HelpCircle
              size={16}
              className={`ml-2 cursor-help ${iconHoverColor}`}
            />
          </label>
          <select
            name="roofOrientation"
            value={inputs.roofOrientation}
            onChange={handleInputChange}
            className={`w-full p-2 rounded ${inputBgColor} ${inputBorderColor} border ${textColor}`}
          >
            <option value="North">North</option>
            <option value="East">East</option>
            <option value="West">West</option>
            <option value="South">South</option>
          </select>
          <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
            The direction your roof faces. North-facing is optimal in the
            Southern Hemisphere.
          </div>
        </div>
        <div className="relative group">
          <label className="block mb-2 font-semibold flex items-center">
            Panel Type
            <HelpCircle
              size={16}
              className={`ml-2 cursor-help ${iconHoverColor}`}
            />
          </label>
          <select
            name="panelType"
            value={inputs.panelType}
            onChange={handleInputChange}
            className={`w-full p-2 rounded ${inputBgColor} ${inputBorderColor} border ${textColor}`}
          >
            <option value="monocrystalline">Monocrystalline</option>
            <option value="polycrystalline">Polycrystalline</option>
            <option value="thinFilm">Thin Film</option>
          </select>
          <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
            Different panel types have varying efficiencies and space
            requirements.
          </div>
        </div>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-yellow-200 text-yellow-800 rounded">
          <p>{error}</p>
          <p>We're using estimated values for calculations. For more accurate results, please try again later or consult with a solar professional.</p>
        </div>
      )}

      {(results.recommendedSystemSize || error) && (
        <div className={`p-4 rounded-lg ${resultsBgColor} mb-6`}>
          <h2
            className={`text-2xl font-bold mb-2 flex items-center ${headerColor}`}
          >
            <Calculator className={`mr-2 ${iconHoverColor}`} /> Your Personalized
            Solar Solution
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ResultItem
              icon={<Sun className={iconHoverColor} />}
              label="Recommended System Size"
              value={`${results.recommendedSystemSize} kW`}
              description="Optimal size based on your energy needs and roof capacity."
            />
            <ResultItem
              icon={<Zap className={iconHoverColor} />}
              label="Number of Panels"
              value={results.numberOfPanels}
              description={`Based on ${getPanelOutput(
                inputs.panelType
              )}W panels.`}
            />
            <ResultItem
              icon={<DollarSign className={iconHoverColor} />}
              label="Estimated System Cost"
              value={`R${results.estimatedCost}`}
              description="Includes panels, inverter, and standard installation."
            />
            <ResultItem
              icon={<Sun className={iconHoverColor} />}
              label="Daily Energy Generation"
              value={`${results.dailyGeneration} kWh`}
              description="Average daily production accounting for your specific conditions."
            />
            <ResultItem
              icon={<TrendingUp className={iconHoverColor} />}
              label="Annual Savings"
              value={`R${results.annualSavings}`}
              description="Potential yearly savings on your Eskom bill."
            />
            <ResultItem
              icon={<Leaf className={iconHoverColor} />}
              label="Annual Carbon Reduction"
              value={`${results.carbonReduction} kg CO₂`}
              description="Your contribution to reducing greenhouse gas emissions."
            />
            <ResultItem
              icon={<Calculator className={iconHoverColor} />}
              label="Payback Period"
              value={`${results.paybackPeriod} years`}
              description="Estimated time to recover your initial investment."
            />
            <ResultItem
              icon={<TrendingUp className={iconHoverColor} />}
              label="25-Year ROI"
              value={`${results.roiAfter25Years}%`}
              description="Return on investment over the system's 25-year lifespan."
            />
          </div>
        </div>
      )}

      <div className="mb-8">
        <h3 className={`text-xl font-bold mb-4 ${headerColor}`}>
          Frequently Asked Questions
        </h3>
        {commonQuestions.map((q, index) => (
          <div key={index} className="mb-4">
            <button
              className={`flex justify-between items-center w-full text-left font-semibold p-2 rounded ${
                isDarkMode
                  ? 'bg-blue-800 hover:bg-blue-700'
                  : 'bg-amber-100 hover:bg-amber-200'
              } transition-colors duration-300`}
              onClick={() =>
                setActiveQuestion(activeQuestion === index ? null : index)
              }
            >
              {q.q}
              <ArrowRight
                className={`transform transition-transform duration-300 ${
                  activeQuestion === index ? 'rotate-90' : ''
                } ${iconHoverColor}`}
              />
            </button>
            {activeQuestion === index && (
              <p
                className={`mt-2 p-2 rounded ${
                  isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
                }`}
              >
                {q.a}
              </p>
            )}
          </div>
        ))}
      </div>

      <div className={`p-4 rounded-lg ${resultsBgColor}`}>
        <h3
          className={`text-xl font-bold mb-2 flex items-center ${headerColor}`}
        >
          <Info className={`mr-2 ${iconHoverColor}`} /> Next Steps
        </h3>
        <p>
          Based on these results, you now have a good starting point for your
          solar panel system. Remember, these are estimates and your actual
          needs may vary. For a precise quote and professional assessment:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>Consult with a certified solar installer</li>
          <li>Consider your future energy needs</li>
          <li>
            Explore available solar incentives and rebates in your area
          </li>
        </ul>
        <button className="mt-4 px-6 py-2 bg-green-500 hover:bg-green-600 text-white font-bold rounded transition-colors duration-300">
          Get a Professional Assessment
        </button>
      </div>

      <div className="mt-8 text-sm text-center">
        <p>
          This calculator provides estimates based on typical South African
          conditions and current market prices. Actual results may vary. Always
          consult with a certified solar installer for a detailed assessment.
        </p>
        <p className="mt-2">
          © {new Date().getFullYear()} GetSolar. All rights reserved.{' '}
          <a href="/privacy-policy" className="underline">
            Privacy Policy
          </a>{' '}
          |{' '}
          <a href="/terms-of-service" className="underline">
            Terms of Service
          </a>
        </p>
      </div>
    </div>
  );
};

const InputField = ({
  label,
  name,
  value,
  onChange,
  tooltip,
  bgColor,
  borderColor,
  textColor,
  iconHoverColor,
  type,
}) => (
  <div className="relative group">
    <label className="block mb-2 font-semibold flex items-center">
      {label}
      <HelpCircle size={16} className={`ml-2 cursor-help ${iconHoverColor}`} />
    </label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className={`w-full p-2 rounded ${bgColor} ${borderColor} border ${textColor}`}
      aria-label={label}
      aria-describedby={`${name}-tooltip`}
    />
    <div
      id={`${name}-tooltip`}
      role="tooltip"
      className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"
    >
      {tooltip}
    </div>
  </div>
);

const ResultItem = ({ icon, label, value, description }) => (
  <div className="flex items-start">
    <div className="mr-3 mt-1">{icon}</div>
    <div>
      <p className="font-semibold">{label}</p>
      <p className="text-lg font-bold">{value}</p>
      <p className="text-sm">{description}</p>
    </div>
  </div>
);

export default PanelSizeCalculator;