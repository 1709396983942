import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { useTheme } from '../../contexts/ThemeContext';

const Layout = ({ children }) => {
  const { isDarkMode } = useTheme();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const mainClass = isDarkMode
    ? 'bg-blue-900 text-blue-100'
    : 'bg-amber-50 text-amber-900';

  const contentClass = isSidebarExpanded ? 'ml-64' : 'ml-20';

  return (
    <div className={`flex min-h-screen ${mainClass}`}>
      <Sidebar isExpanded={isSidebarExpanded} setIsExpanded={setIsSidebarExpanded} />
      <div className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${contentClass}`}>
        <Header toggleSidebar={() => setIsSidebarExpanded(!isSidebarExpanded)} isSidebarExpanded={isSidebarExpanded} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto pt-16 md:pt-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;