import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bell, Search, User, ChevronDown, Sun, Moon } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../../contexts/AuthContext';

const Header = ({ isSidebarExpanded }) => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { currentUser, logout } = useAuth();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [notifications, setNotifications] = useState(3);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const controlHeader = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);
      return () => {
        window.removeEventListener('scroll', controlHeader);
      };
    }
  }, [lastScrollY]);

  const headerClass = isDarkMode ? 'bg-blue-800' : 'bg-amber-800';
  const hoverClass = isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-700';

  return (
    <header className={`fixed top-0 right-0 transition-all duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'} ${headerClass} shadow-lg z-40 ${isSidebarExpanded ? 'left-64' : 'left-20'}`}>
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-yellow-400">Solar Insight</Link>
          <div className="flex items-center space-x-6">
            <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} hoverClass={hoverClass} />
            <SearchBar isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} isDarkMode={isDarkMode} />
            <NotificationBell notifications={notifications} hoverClass={hoverClass} />
            <UserMenu 
              isUserMenuOpen={isUserMenuOpen} 
              setIsUserMenuOpen={setIsUserMenuOpen} 
              isDarkMode={isDarkMode} 
              hoverClass={hoverClass}
              currentUser={currentUser}
              logout={logout}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

const SearchBar = ({ isSearchOpen, setIsSearchOpen, isDarkMode }) => (
  <div className="relative">
    <button className={`p-2 rounded-full ${isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-700'}`} onClick={() => setIsSearchOpen(!isSearchOpen)}>
      <Search size={20} className="text-white" />
    </button>
    {isSearchOpen && (
      <input
        type="text"
        placeholder="Search..."
        className={`absolute left-0 top-full mt-2 p-2 rounded ${isDarkMode ? 'bg-blue-700 text-white placeholder-blue-300' : 'bg-amber-700 text-white placeholder-amber-300'}`}
      />
    )}
  </div>
);

const NotificationBell = ({ notifications, hoverClass }) => (
  <button className={`p-2 rounded-full ${hoverClass} relative`}>
    <Bell size={20} className="text-white" />
    {notifications > 0 && (
      <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
        {notifications}
      </span>
    )}
  </button>
);

const UserMenu = ({ isUserMenuOpen, setIsUserMenuOpen, isDarkMode, hoverClass, currentUser, logout }) => (
  <div className="relative">
    <button 
      className={`flex items-center space-x-2 p-2 rounded ${hoverClass}`}
      onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
    >
      <User size={20} className="text-white" />
      <span className="text-white">{currentUser ? currentUser.displayName : 'Guest'}</span>
      <ChevronDown size={16} className="text-white" />
    </button>
    {isUserMenuOpen && (
      <div className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ${isDarkMode ? 'bg-blue-700' : 'bg-amber-700'}`}>
        <div className="py-1">
          {currentUser ? (
            <>
              <Link to="/profile" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Profile</Link>
              <Link to="/settings" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Settings</Link>
              <button onClick={logout} className={`block w-full text-left px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Sign out</button>
            </>
          ) : (
            <Link to="/login" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Sign in</Link>
          )}
        </div>
      </div>
    )}
  </div>
);

const ThemeToggle = ({ isDarkMode, toggleTheme, hoverClass }) => (
  <button onClick={toggleTheme} className={`p-2 rounded-full ${hoverClass}`}>
    {isDarkMode ? <Sun size={20} className="text-white" /> : <Moon size={20} className="text-white" />}
  </button>
);

export default Header;