import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Zap } from 'lucide-react';

const InverterPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'String Inverters', description: 'Suitable for systems with consistent sunlight exposure' },
    { name: 'Microinverters', description: 'Ideal for systems with partial shading or complex roof structures' },
    { name: 'Hybrid Inverters', description: 'Combines solar and battery storage capabilities' },
  ];

  const features = [
    'Power rating (kW)',
    'Efficiency',
    'MPPT channels',
    'Grid-tie capability',
    'Warranty',
  ];

  const brands = ['SMA', 'Fronius', 'Growatt', 'Deye'];

  return (
    <div>
      <p className="mb-8 text-lg">
        Inverters are crucial for converting DC power from solar panels to AC power for home use.
      </p>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Types of Inverters</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {types.map((type, index) => (
            <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
              <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
              <p>{type.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Key Features</h2>
        <ul className="list-disc list-inside">
          {features.map((feature, index) => (
            <li key={index} className="mb-2">{feature}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Top Brands in SA</h2>
        <div className="flex flex-wrap">
          {brands.map((brand, index) => (
            <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
              {brand}
            </span>
          ))}
        </div>
      </section>

      <section>
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Price Range</h2>
        <p className="text-lg font-semibold">R5,000 - R30,000 (depending on type and capacity)</p>
      </section>
    </div>
  );
};

export default InverterPage;