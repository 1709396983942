import React, { useState, useEffect } from 'react';
import { MessageCircle } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import ChatInterface from './ChatInterface';

const ChatButton = () => {
  const { isDarkMode } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [bobbing, setBobbing] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setBobbing(prev => !prev);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const buttonClass = isDarkMode 
    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
    : 'bg-amber-500 text-white hover:bg-amber-600';

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button 
        onClick={toggleChat}
        className={`fixed bottom-6 right-6 ${buttonClass} rounded-full p-4 shadow-lg hover:scale-110 transition-all transform z-50 ${bobbing ? 'translate-y-1' : '-translate-y-1'}`}
      >
        <MessageCircle className="h-7 w-7" />
      </button>

      {isOpen && <ChatInterface onClose={toggleChat} />}
    </>
  );
};

export default ChatButton;
