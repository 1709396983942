import React, { useState } from 'react';
import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../contexts/ThemeContext';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    interest: 'general',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
      interest: 'general',
    });
    alert('Thank you for your message. We will get back to you soon!');
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Contact Us | Solar Insight</title>
        <meta name="description" content="Get in touch with Solar Insight for all your solar energy inquiries and solutions." />
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          Contact Us
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
            <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
              Contact Information
            </h2>
            <dl className="space-y-4">
              <div className="flex items-center">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex-shrink-0">
                  <PhoneIcon className={`h-6 w-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} aria-hidden="true" />
                </dd>
                <dd className="ml-3">+1 (555) 123-4567</dd>
              </div>
              <div className="flex items-center">
                <dt className="sr-only">Email</dt>
                <dd className="flex-shrink-0">
                  <EnvelopeIcon className={`h-6 w-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} aria-hidden="true" />
                </dd>
                <dd className="ml-3">contact@solarsolutions.com</dd>
              </div>
              <div className="flex items-center">
                <dt className="sr-only">Location</dt>
                <dd className="flex-shrink-0">
                  <MapPinIcon className={`h-6 w-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} aria-hidden="true" />
                </dd>
                <dd className="ml-3">123 Solar Street, Sunnyville, CA 12345</dd>
              </div>
            </dl>
          </div>

          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
            <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
              Send us a message
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md ${
                    isDarkMode 
                      ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400' 
                      : 'bg-amber-100 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500'
                  } shadow-sm`}
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md ${
                    isDarkMode 
                      ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400' 
                      : 'bg-amber-100 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500'
                  } shadow-sm`}
                />
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md ${
                    isDarkMode 
                      ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400' 
                      : 'bg-amber-100 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500'
                  } shadow-sm`}
                />
              </div>
              <div>
                <label htmlFor="interest" className="block text-sm font-medium">
                  Interest
                </label>
                <select
                  id="interest"
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md ${
                    isDarkMode 
                      ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400' 
                      : 'bg-amber-100 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500'
                  } shadow-sm`}
                >
                  <option value="general">General Inquiry</option>
                  <option value="residential">Residential Solar</option>
                  <option value="commercial">Commercial Solar</option>
                  <option value="battery">Battery Storage</option>
                </select>
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  className={`mt-1 block w-full rounded-md ${
                    isDarkMode 
                      ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400' 
                      : 'bg-amber-100 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500'
                  } shadow-sm`}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className={`w-full px-4 py-2 rounded-md ${
                    isDarkMode 
                      ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                      : 'bg-amber-500 text-white hover:bg-amber-600'
                  } transition-colors duration-200`}
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;