import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Sun } from 'lucide-react';

const SolarPanelPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'Monocrystalline Solar Panels', efficiency: '20-22%', bestFor: 'Best for limited roof space in urban areas' },
    { name: 'Polycrystalline Solar Panels', efficiency: '15-17%', bestFor: 'More affordable option, popular in residential installations' },
    { name: 'Thin-Film Solar Panels', efficiency: '10-12%', bestFor: 'Flexible, good for unconventional installations' },
  ];

  const features = [
    'Efficiency rating',
    'Temperature coefficient (important for SA\'s hot climate)',
    'Warranty (typically 25 years)',
    'Hail resistance (crucial for some SA regions)',
  ];

  const brands = ['Canadian Solar', 'JA Solar', 'Jinko Solar', 'LONGi Solar'];

  return (
    <div>
      <p className="mb-8 text-lg">
        Solar panels are essential for converting sunlight into electricity, crucial for South Africa's sunny climate and frequent load shedding issues.
      </p>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Types of Solar Panels</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {types.map((type, index) => (
            <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
              <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
              <p>Efficiency: {type.efficiency}</p>
              <p>{type.bestFor}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Key Features</h2>
        <ul className="list-disc list-inside">
          {features.map((feature, index) => (
            <li key={index} className="mb-2">{feature}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Top Brands Available in SA</h2>
        <div className="flex flex-wrap">
          {brands.map((brand, index) => (
            <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
              {brand}
            </span>
          ))}
        </div>
      </section>

      <section>
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Price Range</h2>
        <p className="text-lg font-semibold">R1,500 - R5,000 per panel (depending on type and efficiency)</p>
      </section>
    </div>
  );
};

export default SolarPanelPage;