import React from 'react';

const Register = () => {
  return (
    <div>
      <h2>Register</h2>
      {/* Add registration form here */}
    </div>
  );
};

export default Register;