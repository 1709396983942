import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useTheme } from '../../contexts/ThemeContext';
import { HelpCircle } from 'lucide-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SavingsCalculator = () => {
  const { isDarkMode } = useTheme();
  const [inputs, setInputs] = useState({
    monthlyBill: 200,
    systemSize: 5,
    electricityRate: 2.5,
    annualIncrease: 6,
    systemCost: 75000,
  });
  const [savings, setSavings] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    calculateSavings();
  }, [inputs]);

  useEffect(() => {
    const savedInputs = localStorage.getItem('solarCalculatorInputs');
    if (savedInputs) {
      setInputs(JSON.parse(savedInputs));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('solarCalculatorInputs', JSON.stringify(inputs));
  }, [inputs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) return; // Prevent negative values
    setInputs(prev => ({ ...prev, [name]: parseFloat(value) }));
  };

  const calculateSavings = () => {
    const { monthlyBill, systemSize, electricityRate, annualIncrease, systemCost } = inputs;

    if (isNaN(monthlyBill) || isNaN(systemSize) || isNaN(electricityRate) || isNaN(annualIncrease) || isNaN(systemCost)) {
      setError('Please enter valid numeric values for all fields.');
      return;
    }

    const annualProduction = systemSize * 1500; // Assuming 1500 kWh per kW per year in South Africa
    const annualConsumption = (monthlyBill / electricityRate) * 12;
    const offsetPercentage = Math.min(annualProduction / annualConsumption, 1);
    const firstYearSavings = annualProduction * electricityRate;

    const savingsData = [firstYearSavings];
    let cumulativeSavings = firstYearSavings;
    for (let i = 1; i < 25; i++) {
      const yearSavings = savingsData[i - 1] * (1 + annualIncrease / 100);
      savingsData.push(yearSavings);
      cumulativeSavings += yearSavings;
    }

    const roi = ((cumulativeSavings - systemCost) / systemCost) * 100;
    const paybackPeriod = savingsData.findIndex(data => data >= systemCost) + 1 || '>25';

    setSavings({
      firstYear: firstYearSavings.toFixed(2),
      cumulative25Years: cumulativeSavings.toFixed(2),
      offsetPercentage: (offsetPercentage * 100).toFixed(1),
      roi: roi.toFixed(2),
      paybackPeriod,
    });

    setChartData({
      labels: Array.from({ length: 25 }, (_, i) => `Year ${i + 1}`),
      datasets: [
        {
          label: 'Annual Savings',
          data: savingsData,
          borderColor: isDarkMode ? 'rgba(255, 206, 86, 1)' : 'rgba(75, 192, 192, 1)',
          backgroundColor: isDarkMode ? 'rgba(255, 206, 86, 0.2)' : 'rgba(75, 192, 192, 0.2)',
        },
        {
          label: 'Cumulative Savings',
          data: savingsData.map((_, index) => 
            savingsData.slice(0, index + 1).reduce((a, b) => a + b, 0)
          ),
          borderColor: isDarkMode ? 'rgba(255, 99, 132, 1)' : 'rgba(153, 102, 255, 1)',
          backgroundColor: isDarkMode ? 'rgba(255, 99, 132, 0.2)' : 'rgba(153, 102, 255, 0.2)',
        },
      ],
    });

    setError(null);
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: isDarkMode ? '#ffffff' : '#000000',
        },
      },
      title: {
        display: true,
        text: 'Projected Annual and Cumulative Savings',
        color: isDarkMode ? '#ffffff' : '#000000',
      },
    },
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? '#ffffff' : '#000000',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: isDarkMode ? '#ffffff' : '#000000',
        },
        title: {
          display: true,
          text: 'Savings (R)',
          color: isDarkMode ? '#ffffff' : '#000000',
        },
      },
    },
  };

  const resetCalculator = () => {
    setInputs({
      monthlyBill: 200,
      systemSize: 5,
      electricityRate: 2.5,
      annualIncrease: 6,
      systemCost: 75000,
    });
    setSavings(null);
    setChartData(null);
    setError(null);
  };

  return (
    <div className={`max-w-4xl mx-auto p-6 ${isDarkMode ? 'bg-blue-900 text-white' : 'bg-amber-50 text-amber-900'}`}>
      <h1 className="text-3xl font-bold mb-6 text-center">Solar Savings Calculator</h1>
      
      <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow rounded-lg p-6`}>
        <div>
          <InputField
            label="Monthly Electricity Bill (R)"
            name="monthlyBill"
            value={inputs.monthlyBill}
            onChange={handleInputChange}
            min={0}
            max={10000}
            step={50}
            tooltip="Your average monthly electricity cost from Eskom or your local provider."
          />
          <InputField
            label="Solar System Size (kW)"
            name="systemSize"
            value={inputs.systemSize}
            onChange={handleInputChange}
            min={1}
            max={20}
            step={0.5}
            tooltip="The size of your solar system in kilowatts (kW)."
          />
          <InputField
            label="Electricity Rate (R/kWh)"
            name="electricityRate"
            value={inputs.electricityRate}
            onChange={handleInputChange}
            min={0.1}
            max={5}
            step={0.01}
            tooltip="The current price you pay per kilowatt-hour (kWh) of electricity."
          />
          <InputField
            label="Annual Electricity Price Increase (%)"
            name="annualIncrease"
            value={inputs.annualIncrease}
            onChange={handleInputChange}
            min={0}
            max={15}
            step={0.5}
            tooltip="The expected annual increase in electricity prices."
          />
          <InputField
            label="System Cost (R)"
            name="systemCost"
            value={inputs.systemCost}
            onChange={handleInputChange}
            min={0}
            max={1000000}
            step={1000}
            tooltip="The total cost of your solar system including installation."
          />
        </div>
        
        {savings && (
          <div className="text-center flex flex-col justify-center">
            <h2 className="text-2xl font-semibold mb-4">Estimated Savings</h2>
            <p className="text-xl mb-2">First Year: <span className="font-bold text-green-500">R{savings.firstYear}</span></p>
            <p className="text-xl mb-2">25 Year Cumulative: <span className="font-bold text-green-500">R{savings.cumulative25Years}</span></p>
            <p className="text-xl mb-2">Energy Offset: <span className="font-bold text-blue-500">{savings.offsetPercentage}%</span></p>
            <p className="text-xl mb-2">Return on Investment: <span className="font-bold text-blue-500">{savings.roi}%</span></p>
            <p className="text-xl mb-2">Payback Period: <span className="font-bold text-blue-500">{savings.paybackPeriod} years</span></p>
          </div>
        )}
      </div>
      
      {error && (
        <div className="mt-4 p-4 bg-red-200 text-red-800 rounded">
          {error}
        </div>
      )}

      {chartData && (
        <div className={`mt-8 p-4 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} rounded-lg shadow`}>
          <Line options={chartOptions} data={chartData} />
        </div>
      )}

      <button
        onClick={resetCalculator}
        className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
      >
        Reset Calculator
      </button>
    </div>
  );
};

const InputField = ({ label, name, value, onChange, min, max, step, tooltip }) => {
  const { isDarkMode } = useTheme();
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2 flex items-center">
        {label}
        <span className="ml-2 cursor-help" data-tooltip-id={`tooltip-${name}`}><HelpCircle size={16} /></span>
      </label>
      <input
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
          isDarkMode 
            ? 'bg-blue-700 border-blue-600 focus:ring-yellow-400 text-white' 
            : 'bg-amber-50 border-amber-300 focus:ring-amber-400 text-amber-900'
        }`}
        aria-label={label}
      />
      <ReactTooltip id={`tooltip-${name}`} place="right" effect="solid">
        {tooltip}
      </ReactTooltip>
    </div>
  );
};

export default SavingsCalculator;