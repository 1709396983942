import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';

const ChatInterface = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  useEffect(() => {
    // Initial greeting message
    setMessages([
      { text: "Hello, need some solar help?", sender: 'bot' },
      { text: "Please choose an email to continue:", sender: 'bot' },
      { text: "1. sales@solarinsight.co.za", sender: 'bot' },
      { text: "2. support@solarinsight.co.za", sender: 'bot' },
      { text: "3. info@solarinsight.co.za", sender: 'bot' }
    ]);
  }, []);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      setMessages(prev => [...prev, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
      // Here you would typically send the message to your backend
      // and then receive a response to display
      setTimeout(() => {
        setMessages(prev => [...prev, { text: "Thank you for your message. An agent will respond shortly.", sender: 'bot' }]);
      }, 1000);
    }
  };

  return (
    <div className={`fixed bottom-20 right-6 w-80 h-96 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} rounded-lg shadow-xl flex flex-col z-50`}>
      <div className="p-4 bg-amber-800 rounded-t-lg flex justify-between items-center">
        <h3 className="font-bold text-yellow-400">Chat with us</h3>
        <button onClick={onClose} className="text-yellow-400 hover:text-yellow-300">
          <X size={20} />
        </button>
      </div>
      <div className="flex-grow overflow-y-auto p-4 space-y-2">
        {messages.map((message, index) => (
          <div key={index} className={`${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded-lg ${
              message.sender === 'user' 
                ? (isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-200 text-amber-900')
                : (isDarkMode ? 'bg-blue-700 text-yellow-400' : 'bg-amber-100 text-amber-800')
            }`}>
              {message.text}
            </span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSendMessage} className="p-4 border-t border-gray-200">
        <div className="flex">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            className={`flex-grow p-2 rounded-l-md ${
              isDarkMode 
                ? 'bg-blue-700 text-yellow-400 placeholder-yellow-400' 
                : 'bg-amber-100 text-amber-900 placeholder-amber-500'
            } focus:outline-none`}
            placeholder="Type your message..."
          />
          <button 
            type="submit"
            className={`px-4 py-2 rounded-r-md ${
              isDarkMode
                ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                : 'bg-amber-500 text-white hover:bg-amber-600'
            }`}
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatInterface;
