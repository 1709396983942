import React, { useState, useEffect } from 'react';
import { LightBulbIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../contexts/ThemeContext';
import { Helmet } from 'react-helmet';

let Wrapper, Status;
try {
  ({ Wrapper, Status } = require("@googlemaps/react-wrapper"));
} catch (error) {
  console.error("Failed to import @googlemaps/react-wrapper:", error);
}

const MapComponent = ({ center, zoom }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && window.google) {
      new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });
    }
  });

  return <div ref={ref} id="map" style={{ width: '100%', height: '400px' }} />;
};

const LoadSheddingPage = () => {
  const { isDarkMode } = useTheme();
  const [currentStage, setCurrentStage] = useState(2);
  const [nextOutage, setNextOutage] = useState('14:00 - 16:30');
  const [userLocation, setUserLocation] = useState({ lat: -26.2041, lng: 28.0473 });
  const [locationError, setLocationError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocationError("Unable to retrieve your location. Please check your browser settings.");
        }
      );
    } else {
      setLocationError("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchResults([
      { area: 'Johannesburg Central', stage: 4, nextOutage: '18:00 - 20:30' },
      { area: 'Sandton', stage: 3, nextOutage: '16:00 - 18:30' },
      { area: 'Soweto', stage: 5, nextOutage: '20:00 - 22:30' },
    ]);
  };

  const render = (status) => {
    if (status === Status.LOADING) return <h3 className={`text-lg ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>Loading map...</h3>;
    if (status === Status.FAILURE) return <h3 className="text-red-600 dark:text-red-400">Error loading map</h3>;
    return null;
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Load Shedding Information | Solar Insight</title>
        <meta name="description" content="Get real-time load shedding information and power-saving tips." />
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          Load Shedding Information
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
            <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Current Status</h2>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className={`text-lg ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>Current Stage:</span>
                <span className="text-3xl font-bold text-red-600 dark:text-red-400">Stage {currentStage}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className={`text-lg ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>Next Outage:</span>
                <span className="text-3xl font-bold">{nextOutage}</span>
              </div>
            </div>
          </div>
          
          <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
            <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Your Location</h2>
            {locationError ? (
              <p className="text-red-600 dark:text-red-400">{locationError}</p>
            ) : Wrapper ? (
              <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
                <MapComponent center={userLocation} zoom={10} />
              </Wrapper>
            ) : (
              <p className={`text-lg ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>Map loading failed. Please check your internet connection and try again.</p>
            )}
          </div>
        </div>
        
        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Search Your Area</h2>
          <form onSubmit={handleSearch} className="flex mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Enter your suburb or town"
              className={`flex-grow p-2 rounded-l-md ${
                isDarkMode 
                  ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400' 
                  : 'bg-amber-100 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500'
              } shadow-sm`}
            />
            <button type="submit" className={`p-2 rounded-r-md ${
              isDarkMode 
                ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                : 'bg-amber-500 text-white hover:bg-amber-600'
              } transition-colors duration-200`}>
              <MagnifyingGlassIcon className="h-6 w-6" />
            </button>
          </form>
          {searchResults.length > 0 && (
            <div className="mt-4">
              <h3 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Search Results:</h3>
              <ul className="space-y-2">
                {searchResults.map((result, index) => (
                  <li key={index} className={`p-3 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'}`}>
                    <p className="font-semibold">{result.area}</p>
                    <p>Stage: {result.stage}</p>
                    <p>Next Outage: {result.nextOutage}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        
        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Understanding Load Shedding Stages</h2>
          <p className="mb-4">Load shedding is implemented in stages, from Stage 1 to Stage 8. Each stage allows for a certain amount of the national load to be shed:</p>
          <ul className="list-disc list-inside space-y-2 mb-4">
            {[1, 2, 3, 4, 5, 6, 7, 8].map(stage => (
              <li key={stage}>Stage {stage}: Up to {stage * 1000} MW</li>
            ))}
          </ul>
          <p>Load shedding in stages 1 to 4 is typically implemented in 2-hour blocks, with some areas experiencing 4-hour blocks. Higher stages may result in longer and more frequent outages.</p>
        </div>
        
        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Power Saving Tips</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[
              "Turn off all non-essential appliances",
              "Use energy-efficient LED bulbs",
              "Invest in a solar power system",
              "Use natural light when possible",
              "Unplug devices when not in use",
              "Use power strips to reduce phantom loads"
            ].map((tip, index) => (
              <li key={index} className={`flex items-start p-3 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'}`}>
                <LightBulbIcon className={`h-6 w-6 mr-2 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span>{tip}</span>
              </li>
            ))}
          </ul>
        </div>
      </main>
    </div>
  );
};

export default LoadSheddingPage;