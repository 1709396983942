import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { Sun, Moon, Battery, Box, Home, Newspaper, Lightbulb, Star, PhoneCall, ChevronDown, ChevronUp, Search, Zap, Calculator, ChevronLeft, ChevronRight, X, DollarSign, Anchor, Cpu } from 'lucide-react';

const Sidebar = ({ isExpanded, setIsExpanded }) => {
  const { isDarkMode, toggleTheme } = useTheme();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isCalculatorsOpen, setIsCalculatorsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const sidebarClass = isExpanded ? 'w-64' : 'w-20';

  const linkClass = (path) => `
    flex items-center p-2 rounded-lg
    ${isDarkMode ? 'text-blue-100 hover:bg-blue-700' : 'text-amber-100 hover:bg-amber-700'}
    ${location.pathname === path ? 'bg-yellow-400 text-blue-900 font-semibold' : ''}
    hover:text-yellow-400 transition-colors duration-200
  `;

  const iconClass = "w-6 h-6";
  const textClass = isExpanded ? "ml-3" : "hidden";

  const handleProductsClick = () => {
    setIsProductsOpen(!isProductsOpen);
    navigate('/product/all');
  };

  const handleCalculatorsClick = () => {
    setIsCalculatorsOpen(!isCalculatorsOpen);
  };

  return (
    <div 
      className={`${isDarkMode ? 'bg-blue-800' : 'bg-amber-900'} text-white fixed inset-y-0 left-0 z-50 ${sidebarClass} overflow-y-auto transition-all duration-300 ease-in-out custom-scrollbar`}
    >
      <div className="flex items-center justify-between p-4 border-b border-amber-300">
        <div className="flex items-center">
          {isExpanded && <h1 className="text-xl font-bold text-yellow-400 mr-2">Solar Insight</h1>}
          <button 
            onClick={toggleTheme} 
            className={`p-2 rounded-full ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-800 hover:bg-amber-700'}`}
          >
            {isDarkMode ? <Sun className={`${iconClass} text-yellow-400`} /> : <Moon className={`${iconClass} text-yellow-400`} />}
          </button>
        </div>
        <button onClick={() => setIsExpanded(!isExpanded)} className="text-yellow-400 hover:text-yellow-300">
          {isExpanded ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
        </button>
      </div>
      
      <nav className="mt-5 px-2">
        <ul className="space-y-2">
          <SidebarItem to="/" icon={<Home className={iconClass} />} text="Home" isExpanded={isExpanded} linkClass={linkClass} />
          <SidebarDropdown 
            icon={<Box className={iconClass} />} 
            text="Products" 
            isExpanded={isExpanded} 
            isOpen={isProductsOpen} 
            onClick={handleProductsClick}
            linkClass={linkClass}
          >
            <SidebarItem to="/product/panels" icon={<Sun size={16} />} text="Solar Panels" isExpanded={isExpanded} linkClass={linkClass} />
            <SidebarItem to="/product/batteries" icon={<Battery size={16} />} text="Batteries" isExpanded={isExpanded} linkClass={linkClass} />
            <SidebarItem to="/product/inverters" icon={<Zap size={16} />} text="Inverters" isExpanded={isExpanded} linkClass={linkClass} />
            <SidebarItem to="/product/mounting" icon={<Anchor size={16} />} text="Mounting Systems" isExpanded={isExpanded} linkClass={linkClass} />
            <SidebarItem to="/product/controllers" icon={<Cpu size={16} />} text="Charge Controllers" isExpanded={isExpanded} linkClass={linkClass} />
          </SidebarDropdown>
          <SidebarItem to="/load-shedding" icon={<Zap className={iconClass} />} text="Load Shedding" isExpanded={isExpanded} linkClass={linkClass} />
          <SidebarDropdown 
            icon={<Calculator className={iconClass} />} 
            text="Calculators" 
            isExpanded={isExpanded} 
            isOpen={isCalculatorsOpen} 
            onClick={handleCalculatorsClick}
            linkClass={linkClass}
          >
            <SidebarItem to="/calculators/savings" icon={<DollarSign size={16} />} text="Savings Calculator" isExpanded={isExpanded} linkClass={linkClass} />
            <SidebarItem to="/calculators/panel" icon={<Sun size={16} />} text="Panel Size Calculator" isExpanded={isExpanded} linkClass={linkClass} />
            <SidebarItem to="/calculators/battery" icon={<Battery size={16} />} text="Battery Capacity Calculator" isExpanded={isExpanded} linkClass={linkClass} />
          </SidebarDropdown>
          <SidebarItem to="/news" icon={<Newspaper className={iconClass} />} text="News" isExpanded={isExpanded} linkClass={linkClass} />
          <SidebarItem to="/insights" icon={<Lightbulb className={iconClass} />} text="Insights" isExpanded={isExpanded} linkClass={linkClass} />
          <SidebarItem to="/reviews" icon={<Star className={iconClass} />} text="Reviews" isExpanded={isExpanded} linkClass={linkClass} />
        </ul>
      </nav>

      <div className="mt-auto p-4 border-t border-amber-300">
        <SidebarItem to="/contact" icon={<PhoneCall className={iconClass} />} text="Contact Us" isExpanded={isExpanded} linkClass={linkClass} />
      </div>
    </div>
  );
};

const SidebarItem = ({ to, icon, text, isExpanded, linkClass }) => (
  <li>
    <Link to={to} className={linkClass(to)}>
      {icon}
      {isExpanded && <span className="ml-3">{text}</span>}
    </Link>
  </li>
);

const SidebarDropdown = ({ icon, text, isExpanded, isOpen, onClick, linkClass, children }) => (
  <li>
    <button 
      onClick={onClick} 
      className={`w-full ${linkClass("")}`}
    >
      {icon}
      {isExpanded && (
        <>
          <span className="ml-3">{text}</span>
          {isOpen ? <ChevronUp size={20} className="ml-auto" /> : <ChevronDown size={20} className="ml-auto" />}
        </>
      )}
    </button>
    {isOpen && isExpanded && (
      <ul className="pl-6 mt-2 space-y-2">
        {children}
      </ul>
    )}
  </li>
);

export default Sidebar;