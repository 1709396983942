import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploadProductImage, deleteProductImage } from '../../services/storage';

const products = {
  1: { id: 1, name: 'Solar Panel 300W', price: 199.99, image: 'https://example.com/solar-panel.jpg', description: 'High-efficiency monocrystalline solar panel' },
  2: { id: 2, name: 'Inverter 5kW', price: 999.99, image: 'https://example.com/inverter.jpg', description: 'Pure sine wave inverter for seamless power conversion' },
  3: { id: 3, name: 'Battery 10kWh', price: 4999.99, image: 'https://example.com/battery.jpg', description: 'Lithium-ion battery for reliable energy storage' },
};

const ProductDetail = () => {
  const { id } = useParams();
  const product = products[id];
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      setError(null);
      
      // Delete old image if it exists
      if (product.imageUrl) {
        await deleteProductImage(product.imageUrl);
      }

      // Upload new image
      const imageUrl = await uploadProductImage(file, product.id);
      
      // Here you would typically update your product data in Firestore
      // with the new imageUrl
      
    } catch (err) {
      setError('Failed to upload image. Please try again.');
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  if (!product) {
    return <div className="text-center text-2xl text-gray-800 dark:text-white">Product not found</div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">{product.name}</h1>
      
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
        <img src={product.image} alt={product.name} className="w-full h-64 object-cover" />
        <div className="p-6">
          <p className="text-gray-600 dark:text-gray-300 mb-4">{product.description}</p>
          <p className="text-2xl font-bold text-gray-800 dark:text-white">${product.price.toFixed(2)}</p>
          <button className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Add to Cart
          </button>
        </div>
      </div>
      
      <div className="image-upload-section">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          disabled={uploading}
        />
        {uploading && <p>Uploading...</p>}
        {error && <p className="error">{error}</p>}
        
        {product.imageUrl && (
          <img 
            src={product.imageUrl} 
            alt={product.name}
            style={{ maxWidth: '300px' }} 
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetail;