import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { 
  Sun, Zap, Calculator, Newspaper, 
  Lightbulb, Star, ArrowRight, MessageCircle,
  DollarSign, Shield, Globe, BarChart, Battery
} from 'lucide-react';
import { Helmet } from 'react-helmet';
import SavingsCalculator from './calculator/SavingsCalculator';
import PanelSizeCalculator from './calculator/PanelSizeCalculator';
import BatteryCapacityCalculator from './calculator/BatteryCapacityCalculator';
import ChatButton from './ChatButton';

const Home = () => {
  const { isDarkMode } = useTheme();
  const [email, setEmail] = useState('');
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [activeCalculator, setActiveCalculator] = useState('savings');

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.pageYOffset;
      setScrollProgress((currentScroll / totalScroll) * 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);
    // Add email submission logic here
  };

  const bgClass = isDarkMode ? 'bg-blue-900' : 'bg-amber-50';
  const textClass = isDarkMode ? 'text-amber-100' : 'text-amber-900';
  const accentClass = isDarkMode ? 'text-yellow-400' : 'text-amber-600';
  const buttonClass = isDarkMode 
    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
    : 'bg-amber-500 text-white hover:bg-amber-600';

  const calculatorComponents = {
    savings: SavingsCalculator,
    panel: PanelSizeCalculator,
    battery: BatteryCapacityCalculator
  };

  const ActiveCalculator = calculatorComponents[activeCalculator];

  return (
    <div className={`${bgClass} ${textClass}`}>
      <Helmet>
        <title>Solar Insight | Leading Solar Energy Solutions in South Africa</title>
        <meta name="description" content="Discover sustainable solar energy solutions for your home or business. Get expert advice, custom designs, and professional installation from Solar Insight." />
        <meta name="keywords" content="solar energy, solar panels, renewable energy, South Africa, energy savings" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <main className="py-10">
          {/* Progress bar */}
          <div className="fixed top-0 left-0 w-full h-1 bg-gray-300 z-50">
            <div 
              className={`h-full ${isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'}`} 
              style={{ width: `${scrollProgress}%` }}
            ></div>
          </div>

          {/* Hero Section */}
          <section className={`mb-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'} p-8 md:p-12 rounded-lg text-center shadow-lg`}>
            <h1 className={`text-5xl font-bold mb-6 ${accentClass}`}>Power Your Future with Solar</h1>
            <p className="text-2xl mb-8">Sustainable energy solutions for a brighter tomorrow</p>
            <Link to="/quote" className={`${buttonClass} font-bold py-3 px-8 rounded-full transition duration-300 text-lg`}>
              Get Your Free Solar Quote
            </Link>
          </section>

          {/* Lead Capture Form */}
          <section className={`mb-16 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-8 rounded-lg shadow-xl`}>
            <h2 className={`text-3xl font-bold mb-6 text-center ${accentClass}`}>Discover Your Solar Savings</h2>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`w-full p-3 mb-4 border-2 ${isDarkMode ? 'bg-blue-700 border-yellow-400' : 'bg-amber-50 border-amber-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 ${isDarkMode ? 'focus:ring-yellow-400' : 'focus:ring-amber-400'}`}
                required
              />
              <button type="submit" className={`w-full ${buttonClass} font-bold py-3 px-6 rounded-md transition-colors`}>
                Calculate My Savings
              </button>
            </form>
          </section>

          {/* Why Choose Solar */}
          <section className="mb-16">
            <h2 className={`text-3xl font-bold mb-8 text-center ${accentClass}`}>Why Choose Solar?</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              <FeatureCard
                icon={<DollarSign className={`h-12 w-12 ${accentClass}`} />}
                title="Save Money"
                description="Reduce your electricity bills and increase your home value."
                isDarkMode={isDarkMode}
              />
              <FeatureCard
                icon={<Shield className={`h-12 w-12 ${accentClass}`} />}
                title="Energy Independence"
                description="Protect yourself from rising energy costs and power outages."
                isDarkMode={isDarkMode}
              />
              <FeatureCard
                icon={<Globe className={`h-12 w-12 ${accentClass}`} />}
                title="Eco-Friendly"
                description="Reduce your carbon footprint and help combat climate change."
                isDarkMode={isDarkMode}
              />
              <FeatureCard
                icon={<BarChart className={`h-12 w-12 ${accentClass}`} />}
                title="Low Maintenance"
                description="Solar panels require minimal upkeep and have a long lifespan."
                isDarkMode={isDarkMode}
              />
            </div>
          </section>

          {/* Our Products */}
          <section className="mb-16">
            <h2 className={`text-3xl font-bold mb-8 text-center ${accentClass}`}>Our Solar Solutions</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <ProductCard 
                title="High-Efficiency Solar Panels" 
                description="State-of-the-art panels for maximum energy production." 
                icon={<Sun className={`h-10 w-10 ${accentClass}`} />}
                isDarkMode={isDarkMode}
              />
              <ProductCard 
                title="Advanced Battery Storage" 
                description="Store excess energy for use during nighttime or outages." 
                icon={<Battery className={`h-10 w-10 ${accentClass}`} />}
                isDarkMode={isDarkMode}
              />
              <ProductCard 
                title="Smart Inverters" 
                description="Optimize your solar system's performance with intelligent control." 
                icon={<Zap className={`h-10 w-10 ${accentClass}`} />}
                isDarkMode={isDarkMode}
              />
            </div>
          </section>

          {/* How It Works */}
          <section className={`mb-16 ${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-8 rounded-lg shadow-xl`}>
            <h2 className={`text-3xl font-bold mb-8 text-center ${accentClass}`}>How It Works</h2>
            <div className="grid md:grid-cols-4 gap-6">
              <StepCard number="1" title="Free Consultation" description="We assess your energy needs and site conditions." isDarkMode={isDarkMode} />
              <StepCard number="2" title="Custom Design" description="Our experts design a system tailored to your home." isDarkMode={isDarkMode} />
              <StepCard number="3" title="Professional Installation" description="Certified technicians install your solar system." isDarkMode={isDarkMode} />
              <StepCard number="4" title="Start Saving" description="Enjoy lower bills and clean energy from day one." isDarkMode={isDarkMode} />
            </div>
          </section>

          {/* Customer Reviews */}
          <section className="mb-16">
            <h2 className={`text-3xl font-bold mb-8 text-center ${accentClass}`}>What Our Customers Say</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <ReviewCard
                name="John Doe"
                review="Solar Insight transformed our home. We're saving money and feeling great about our environmental impact!"
                rating={5}
                isDarkMode={isDarkMode}
              />
              <ReviewCard
                name="Jane Smith"
                review="The team was professional and efficient. Our solar panels look great and perform even better!"
                rating={5}
                isDarkMode={isDarkMode}
              />
              <ReviewCard
                name="Mike Johnson"
                review="I was hesitant at first, but Solar Insight made the whole process easy. Best decision we've made for our home."
                rating={4}
                isDarkMode={isDarkMode}
              />
            </div>
          </section>

          {/* FAQ Section */}
          <section className="mb-16">
            <h2 className={`text-3xl font-bold mb-8 text-center ${accentClass}`}>Frequently Asked Questions</h2>
            <div className="space-y-4">
              <FAQItem 
                question="How much can I save with solar panels?"
                answer="Savings vary depending on your energy usage and location, but many homeowners save 50-90% on their electricity bills."
                isActive={activeQuestion === 0}
                onClick={() => setActiveQuestion(activeQuestion === 0 ? null : 0)}
                isDarkMode={isDarkMode}
              />
              <FAQItem 
                question="How long do solar panels last?"
                answer="Most solar panels come with a 25-30 year warranty and can last even longer with proper maintenance."
                isActive={activeQuestion === 1}
                onClick={() => setActiveQuestion(activeQuestion === 1 ? null : 1)}
                isDarkMode={isDarkMode}
              />
              <FAQItem 
                question="Do I need batteries with my solar system?"
                answer="Batteries are optional but can provide power during outages and maximize your energy independence."
                isActive={activeQuestion === 2}
                onClick={() => setActiveQuestion(activeQuestion === 2 ? null : 2)}
                isDarkMode={isDarkMode}
              />
            </div>
          </section>

          {/* Solar Calculators Section */}
          <section className="mb-16">
            <h2 className={`text-3xl font-bold mb-8 text-center ${accentClass}`}>Solar Calculators</h2>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
              <button 
                onClick={() => setActiveCalculator('savings')} 
                className={`${buttonClass} px-4 py-2 rounded ${activeCalculator === 'savings' ? 'ring-2 ring-offset-2 ring-yellow-400' : ''}`}
              >
                Savings Calculator
              </button>
              <button 
                onClick={() => setActiveCalculator('panel')} 
                className={`${buttonClass} px-4 py-2 rounded ${activeCalculator === 'panel' ? 'ring-2 ring-offset-2 ring-yellow-400' : ''}`}
              >
                Panel Size Calculator
              </button>
              <button 
                onClick={() => setActiveCalculator('battery')} 
                className={`${buttonClass} px-4 py-2 rounded ${activeCalculator === 'battery' ? 'ring-2 ring-offset-2 ring-yellow-400' : ''}`}
              >
                Battery Capacity Calculator
              </button>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-4 sm:p-6 overflow-x-auto">
              <ActiveCalculator />
            </div>
          </section>

          {/* Call to Action */}
          <section className={`${isDarkMode ? 'bg-blue-800' : 'bg-amber-100'} p-12 rounded-lg text-center shadow-lg`}>
            <h2 className={`text-4xl font-bold mb-4 ${accentClass}`}>Ready to Embrace Solar?</h2>
            <p className="text-xl mb-8">Join thousands of happy customers and start your journey to energy independence today!</p>
            <Link to="/quote" className={`${buttonClass} font-bold py-3 px-8 rounded-full transition duration-300 text-lg`}>
              Get Your Free Quote Now
            </Link>
          </section>
        </main>
      </div>

      <ChatButton />
    </div>
  );
};

const FeatureCard = ({ icon, title, description, isDarkMode }) => (
  <div className={`${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-6 rounded-lg shadow-md text-center`}>
    <div className="text-yellow-500 mb-4 flex justify-center">{icon}</div>
    <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-900'}`}>{title}</h3>
    <p className={`${isDarkMode ? 'text-amber-100' : 'text-gray-600'}`}>{description}</p>
  </div>
);

const ProductCard = ({ title, description, icon, isDarkMode }) => (
  <div className={`${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-6 rounded-lg shadow-md text-center`}>
    <div className={`${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'} p-4 rounded-full mb-4 inline-block`}>
      {icon}
    </div>
    <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-900'}`}>{title}</h3>
    <p className={`${isDarkMode ? 'text-amber-100' : 'text-gray-600'} mb-4`}>{description}</p>
    <Link to="/products" className={`${isDarkMode ? 'text-yellow-400' : 'text-amber-600'} hover:text-yellow-500 font-semibold`}>Learn More</Link>
  </div>
);

const StepCard = ({ number, title, description, isDarkMode }) => (
  <div className="text-center">
    <div className={`${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'} text-blue-900 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4 text-xl font-bold`}>{number}</div>
    <h3 className={`text-lg font-bold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-900'}`}>{title}</h3>
    <p className={`${isDarkMode ? 'text-amber-100' : 'text-gray-600'}`}>{description}</p>
  </div>
);

const ReviewCard = ({ name, review, rating, isDarkMode }) => (
  <div className={`${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-6 rounded-lg shadow-md`}>
    <div className="flex items-center mb-2">
      {[...Array(rating)].map((_, i) => (
        <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
      ))}
    </div>
    <p className={`${isDarkMode ? 'text-amber-100' : 'text-gray-600'} mb-4 italic`}>"{review}"</p>
    <p className={`${isDarkMode ? 'text-yellow-400' : 'text-amber-900'} font-semibold`}>- {name}</p>
  </div>
);

const FAQItem = ({ question, answer, isActive, onClick, isDarkMode }) => (
  <div className={`${isDarkMode ? 'bg-blue-800' : 'bg-white'} p-6 rounded-lg shadow-md cursor-pointer`} onClick={onClick}>
    <h4 className={`text-lg font-bold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-900'} flex justify-between items-center`}>
      {question}
      <ArrowRight className={`h-5 w-5 transform transition-transform ${isActive ? 'rotate-90' : ''}`} />
    </h4>
    {isActive && <p className={isDarkMode ? 'text-amber-100' : 'text-gray-600'}>{answer}</p>}
  </div>
);

export default Home;