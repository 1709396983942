import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Battery } from 'lucide-react';

const BatteryPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'Lithium-Ion Batteries', description: 'Popular for home installations, long lifespan, ideal for frequent use during load shedding' },
    { name: 'Lead-Acid Batteries', description: 'Lower upfront cost, require more maintenance' },
  ];

  const features = [
    'Capacity (kWh)',
    'Depth of Discharge (DoD)',
    'Cycle life',
    'Compatibility with SA\'s electrical standards',
  ];

  const brands = ['Freedom Won', 'Pylontech', 'Hubble', 'Dyness'];

  return (
    <div>
      <p className="mb-8 text-lg">
        Essential for storing solar energy to use during load shedding or at night.
      </p>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Types of Batteries</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {types.map((type, index) => (
            <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
              <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
              <p>{type.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Key Features</h2>
        <ul className="list-disc list-inside">
          {features.map((feature, index) => (
            <li key={index} className="mb-2">{feature}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Top Brands in SA</h2>
        <div className="flex flex-wrap">
          {brands.map((brand, index) => (
            <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
              {brand}
            </span>
          ))}
        </div>
      </section>

      <section>
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Price Range</h2>
        <p className="text-lg font-semibold">R50,000 - R150,000 for a home battery system</p>
      </section>
    </div>
  );
};

export default BatteryPage;