import React from 'react';

const Login = () => {
  return (
    <div>
      <h2>Login</h2>
      {/* Add login form here */}
    </div>
  );
};

export default Login;