import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCGBvLbJ6lMapkmdKnPlwcxip8gB_D4mTM",
  authDomain: "solar-insight-sa.firebaseapp.com",
  projectId: "solar-insight-sa",
  storageBucket: "solar-insight-sa.appspot.com",
  messagingSenderId: "360227120626",
  appId: "1:360227120626:web:1b7b39a3b619ff6684b76b",
  measurementId: "G-4JWSGJJJVH"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
