import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Jan', energy: 4000 },
  { name: 'Feb', energy: 3000 },
  { name: 'Mar', energy: 2000 },
  { name: 'Apr', energy: 2780 },
  { name: 'May', energy: 1890 },
  { name: 'Jun', energy: 2390 },
  { name: 'Jul', energy: 3490 },
];

const Dashboard = () => {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Energy Dashboard</h1>
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Energy Production</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="energy" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* Add more dashboard widgets here */}
    </div>
  );
};

export default Dashboard;